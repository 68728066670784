import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {HttpInterceptorService} from '@services/http-interceptor.service';
import {SharedModule} from './shared/shared.module';
import {LoaderService} from '@services/loader.service';
import {PlayersService} from '@services/players.service';
import {ClipboardModule} from "ngx-clipboard";
import {GobackService} from "@services/goback.service";
import {GobackModule} from "@app/shared/goback/goback.module";
import {PipeModule} from "../pipes/PipeModule";
import {RedirectGuard} from "@app/shared/redirectguard";

@NgModule({
	declarations: [AppComponent],
	imports: [BrowserModule, AppRoutingModule, HttpClientModule, SharedModule, ClipboardModule, GobackModule, PipeModule],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true,
		},
		LoaderService,
		PlayersService,
		GobackService,
		RedirectGuard
	],
	bootstrap: [AppComponent],
})
export class AppModule {
}
