import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {LoaderModule} from './loader/loader.module';

@NgModule({
	imports: [CommonModule],
	exports: [CommonModule, FormsModule, LoaderModule]
})
export class SharedModule {
}
