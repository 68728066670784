import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {RedirectGuard} from "@app/shared/redirectguard";

const routes: Routes = [
	{
		path: '',
		canActivate: [RedirectGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
			},
			{
				path: 'vote',
				loadChildren: () => import('./vote/vote.module').then((m) => m.VoteModule),
			},
			{
				path: 'rules',
				loadChildren: () => import('./rules/rules.module').then((m) => m.RulesModule),
			},
			{
				path: 'apply',
				canActivate: [RedirectGuard],
				component: RedirectGuard,
				data: {
					externalUrl: 'https://forms.gle/vaLm1fDDKnLDXmsXA'
				}
			},
			{
				path: '**',
				redirectTo: '',
			},
		],
	},
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
})
export class AppRoutingModule {
}
