import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class GobackService {
	public static GoBackVisibilityChanged: EventEmitter<boolean> = new EventEmitter();

	public static ShowGoback(): void {
		this.GoBackVisibilityChanged.emit(true);
	}

	public static HideGoback(): void {
		this.GoBackVisibilityChanged.emit(false);
	}

}
