import {Pipe, PipeTransform} from "@angular/core";
import {getOrdinalNum} from "../utils/numberutil";

@Pipe({
    name: 'parseOrdinalNumber'
})
export class OrdinalNumberPipe implements PipeTransform {

    transform(value: number): string {
        return getOrdinalNum(value);
    }
}
