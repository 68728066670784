import {EventEmitter, Injectable} from '@angular/core';

@Injectable()
export class LoaderService {
	public static LoaderVisibilityChanged: EventEmitter<boolean> = new EventEmitter();

	public static ShowLoader(): void {
		this.LoaderVisibilityChanged.emit(true);
	}

	public static HideLoader(): void {
		this.LoaderVisibilityChanged.emit(false);
	}

}
