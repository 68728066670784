import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {DestroyableComponent} from '@app/core/destroyable';
import {takeUntil} from 'rxjs/operators';
import {User} from '@models/user.model';

@Injectable()
export class PlayersService extends DestroyableComponent {
	public constructor(private http: HttpClient) {
		super();
	}

	public GetTopVoters(): Promise<User[]> {
		return new Promise<User[]>((resolve, reject) => {
			this.http
				.get<User[]>(`topvoters`)
				.pipe(takeUntil(this.ngUnsubscribe))
				.subscribe(
					(result: User[]) => {
						resolve(result);
					},
					(error: any) => reject(error)
				);
		});
	}


}
