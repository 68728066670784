import {Inject, Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
	constructor() {
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const requestingLocalFiles: boolean = req.url.indexOf('assets') === 0 || req.url.indexOf('assets') === 1 || req.url.includes("https://");

		let handledRequest: any;
		let headers: HttpHeaders = req.headers;

		if (!requestingLocalFiles) {

			handledRequest = req.clone({
				url: 'https://api.site-backend.brutalprison.craftigames.net/' + req.url,
				headers
			});
		} else {
			handledRequest = req.clone();
		}

		return next.handle(handledRequest);
	}
}
