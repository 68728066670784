<app-loader></app-loader>

<div class="header-splash">
    <div class="teata"
         style="height: 100%;width: 100%;background: linear-gradient(transparent 0,rgb(0 0 0 / 53%) 30%,#131b28 100%);">
    </div>
</div>
<header class="triplezone-header">
    <div class="triplezone-wrapper prioritate">
        <app-goback></app-goback>
        <div class="triplezone-header-g">
            <div class="widget minecraft-widget" (click)="minecraftClick()">
                <div class="widget-icon"><span class="player-count">{{ online }}</span>
                    <i class="mdi mdi-minecraft"></i>
                </div>
                <div class="description-widget">
                    <p class="widget-title">play.brutalprison.com</p>
                    <p class="widget-description">click to copy</p>
                </div>
            </div>
            <a routerLink="/">
                <div class="header-logo" >
                    <img src="assets/logo.png" alt="server logo" class="header-logo-image">
                </div>
            </a>
            <div class="mobile__player--count minecraft-widget" (click)="minecraftClick()">
                <p class="player__count"><span class="player-count">0</span> players online</p>
                <p class="__title">play.brutalprison.com</p>
            </div>
            <div class="widget discord-widget" (click)="discordClick()">
                <div class="description-widget">
                    <p class="widget-title">Discord Server</p>
                    <p class="widget-description">click to join</p>
                </div>
                <div class="widget-icon"><span id="discord-count">{{ discordOnline }}</span><i class="mdi mdi-discord"></i>
                </div>
            </div>
        </div>
    </div>
</header>


<div class="triplezone-wrapper">
    <router-outlet></router-outlet>
</div>


<input type="text" id="ipServer" value="play.brutalprison.com">
<div class="triplezone-wrapper">
    <div class="footer-triplezone">
        <div class="footer-copyright">
            <p class="copyright-title">Copyright © Brutal Prison 2021. All Rights Reserved.</p>
            <p class="copyright-subtitle">We are not affiliated with Mojang AB.</p>
        </div>
        <div class="footer__navigation--bar">
            <a href="https://twitter.com/BrutalPrisonMC"><i class="mdi mdi-twitter"></i> Twitter</a>
            <a href="https://instagram.com/brutal.prison"><i class="mdi mdi-instagram"></i> Instagram</a>
            <a href="https://brutalprison.com/discord" class="discord__link"><i class="mdi mdi-discord"></i> Discord</a>
        </div>
    </div>
</div>
