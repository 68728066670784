import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';

@Injectable({providedIn: 'root'})
export class RedirectGuard implements CanActivate {

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (window.location.hostname === 'vote.brutalprison.com') {
            let url = null
            switch (window.location.pathname) {
                case '/1':
                    url = 'https://topminecraftservers.org/server/13710'
                    break
                case '/2':
                    url = 'https://minecraft-mp.com/server/288761/vote/'
                    break
                case '/3':
                    url = 'https://minecraftservers.org/vote/625194'
                    break
                case '/4':
                    url = 'https://topg.org/minecraft-servers/server-425855'
                    break
                case '/5':
                    url = 'https://minecraft-server.net/details/pikanetwork/'
                    break
            }
            if (url != null) {
                window.location.href = url;
            }
            return true;
        }
        if (route.data && route.data['externalUrl']) {
            window.location.href = route.data['externalUrl'];
        }
        return true;
    }

}
