import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OrdinalNumberPipe} from "./OrdinalNumberPipe";

@NgModule({
    imports: [CommonModule],
    declarations: [OrdinalNumberPipe],
    exports: [OrdinalNumberPipe],
})
export class PipeModule {}
