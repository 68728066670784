import {NgModule} from '@angular/core';
import {GobackComponent} from './goback.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from "@angular/router";

@NgModule({
	declarations: [GobackComponent],
	imports: [CommonModule, RouterModule],
	exports: [GobackComponent]
})
export class GobackModule {
}
