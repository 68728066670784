import {Component} from '@angular/core';
import {DestroyableComponent} from '@app/core/destroyable';
import {LoaderService} from '@services/loader.service';
import {takeUntil} from 'rxjs/operators';

@Component({
	selector: 'app-loader',
	templateUrl: 'loader.component.html'
})
export class LoaderComponent extends DestroyableComponent {
	isLoading: boolean = false;

	constructor() {
		super();

		LoaderService.LoaderVisibilityChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => (this.isLoading = value));
	}
}
