import {Component, OnInit} from '@angular/core';
import {DestroyableComponent} from '@core/destroyable';
import {PlayersService} from "@services/players.service";
import Swal from 'sweetalert2'
import {ClipboardService} from "ngx-clipboard";
import {HttpClient} from "@angular/common/http";
import {User} from "@models/user.model";
import {takeUntil} from "rxjs/operators";


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
})
export class AppComponent extends DestroyableComponent implements OnInit {

	public online: number = 0;
	public discordOnline: number = 0;

	constructor(private clipboardApi: ClipboardService, private http: HttpClient) {
		super();
	}

	public ngOnInit(): void {
		this.http
			.get<any>(`https://discordapp.com/api/guilds/684111846047088720/embed.json`)
			.subscribe(value => {
				this.discordOnline = value.presence_count;
			});

		this.http
			.get<any>(`https://api.minetools.eu/ping/play.brutalprison.com/25565`)
			.subscribe(value => {
				this.online = value.players.online;
			});

		// TODO: Get discord and minecraft count
	}


	discordClick() {
		window.location.href = "https://brutalprison.com/discord"
	}

	minecraftClick() {
		this.clipboardApi.copyFromContent("play.brutalprison.com")
		Swal.fire({
			title: 'play.brutalprison.com',
			text: 'We hope to see you online soon!',
			timer: 5000,
		});
	}
}
