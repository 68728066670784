import {Component} from '@angular/core';
import {DestroyableComponent} from '@app/core/destroyable';
import {LoaderService} from '@services/loader.service';
import {takeUntil} from 'rxjs/operators';
import {GobackService} from "@services/goback.service";

@Component({
	selector: 'app-goback',
	templateUrl: 'goback.component.html'
})
export class GobackComponent extends DestroyableComponent {
	goBack: boolean = false;

	constructor() {
		super();

		GobackService.GoBackVisibilityChanged.pipe(takeUntil(this.ngUnsubscribe)).subscribe((value) => (this.goBack = value));
	}
}
